import { createRpcJsonAction } from "@sitepark/ts-rpc";

type ChronoUnit =
	| "NANAO"
	| "MICROS"
	| "MILLIS"
	| "SECONDS"
	| "MINUTES"
	| "HOURS"
	| "DAYS"
	| "WEEKS"
	| "MONTHS"
	| "YEARS";

interface Request {
	usecase: string;
	observationId?: number;
	start: number;
	end: number;
	aggregateUnit?: ChronoUnit;
	aggregateValue?: number;
}

interface Response<R = any> {
	rows: R;
}

const getTimeRange = createRpcJsonAction<Request, Response>(SP.env.rpcPortUrl, "SP\\Kassel\\Rpc\\Sensor", "timeRange");

export default getTimeRange;
