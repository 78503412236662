import getState from "../api/state";
import AbstractNumberPanel, { NumberPanelOptions } from "./AbstractNumberPanel";

interface AuebadNumberPanelOptions extends NumberPanelOptions {
	dataPoint: string;
	dataSource: string;
	observationId?: number;
}

export default class NumberPanel extends AbstractNumberPanel<AuebadNumberPanelOptions> {
	protected async provideValue(): Promise<[number, Date]> {
		const state = await getState({
			observationId: this.options.observationId ?? 0,
			usecase: this.options.dataSource
		});
		const value = state[this.options.dataPoint];
		const date = new Date(state.time);
		return [value, date];
	}
}
