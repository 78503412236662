export type ChronoUnit = "SECONDS" | "MINUTES" | "HOURS" | "DAYS" | "WEEKS" | "MONTHS" | "YEARS";

const secondInMs = 1000;
const minuteInMs = secondInMs * 60;
const hourInMs = minuteInMs * 60;
const dayInMs = hourInMs * 24;
const weekInMs = dayInMs * 7;
const monthInMs = dayInMs * 30;
const yearInMs = dayInMs * 365;

export function chronoUnitToMs(amount: number, unit: ChronoUnit): number {
	switch (unit) {
		case "YEARS":
			return amount * yearInMs;
		case "MONTHS":
			return amount * monthInMs;
		case "WEEKS":
			return amount * weekInMs;
		case "DAYS":
			return amount * dayInMs;
		case "HOURS":
			return amount * hourInMs;
		case "MINUTES":
			return amount * minuteInMs;
		case "SECONDS":
			return amount * secondInMs;
	}
}
