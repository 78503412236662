import type { CountUp, CountUpOptions } from "countup.js";

export interface NumberPanelOptions {
	template?: string;
	max?: number;
	maxDecimals?: number;
	showDate?: boolean;
}

export default abstract class AbstractNumberPanel<O extends NumberPanelOptions> {
	protected readonly options: O;

	protected readonly el: HTMLElement;

	constructor(el: HTMLElement, options: O) {
		this.el = el;
		this.options = options;
		this.init();
	}

	protected abstract provideValue(): Promise<[number, Date]>;

	private getNumberEl(): HTMLElement {
		const el = this.el.querySelector<HTMLElement>("[data-sp-state-number]");
		if (!el) {
			throw new Error("unable to locate number el");
		}
		return el;
	}

	private getDateEl(): HTMLElement {
		const el = this.el.querySelector<HTMLElement>("[data-sp-state-date]");
		if (!el) {
			throw new Error("unable to locate date el");
		}
		return el;
	}

	private getMaxDecimals(): number {
		return this.options.maxDecimals ?? 1;
	}

	private async getValue(): Promise<[number, Date]> {
		let [value, date] = await this.provideValue();
		if (this.options.max !== undefined) {
			value = (value / this.options.max) * 100;
		}
		return [value, date];
	}

	private updateDate(date: Date): void {
		if (this.options.showDate === true) {
			this.getDateEl().innerText = date.toLocaleString();
		}
	}

	private async init(): Promise<void> {
		const [value, date] = await this.getValue();

		const counter = await this.createCounter(value, {
			formattingFn: this.applyTemplate.bind(this),
			decimalPlaces: Math.min(this.countDecimals(value), this.getMaxDecimals()),
			scrollSpyOnce: true
		});
		if (!counter.error) {
			counter.start();
		} else {
			console.error(counter.error);
		}
		this.updateDate(date);
		setInterval(
			async () => {
				const [value, date] = await this.getValue();
				counter.update(value);
				this.updateDate(date);
			},
			5 * 60 * 1000
		);
	}

	private countDecimals(value: number): number {
		if (Math.floor(value.valueOf()) === value.valueOf()) {
			return 0;
		}
		return value.toString().split(".")[1].length || 0;
	}

	private applyTemplate(value: number): string {
		let stringifiedValue = value.toLocaleString("de", {
			useGrouping: true,
			maximumFractionDigits: this.getMaxDecimals()
		});
		if (this.options.max) {
			stringifiedValue = `${stringifiedValue}%`;
		}
		const rawTemplate = this.options.template || "{value}";
		const template = rawTemplate
			.split("{value}")
			.map((part) => {
				return `<span class="SP-NumberPanel__text">${part}</span>`;
			})
			.join("{value}");

		return template.replace("{value}", `<span class="SP-NumberPanel__value">${stringifiedValue}</span>`);
	}

	private async createCounter(value: number, options: CountUpOptions): Promise<CountUp> {
		const { CountUp } = await import("countup.js");
		return new CountUp(this.getNumberEl(), value, options);
	}
}
