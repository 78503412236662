import getState from "../api/state";

type ArithmeticOperator = ">" | ">=" | "<" | "<=";
type Operator = "==" | ArithmeticOperator;

interface Mapper {
	operator: Operator;
	comparison: string | number;
	text: string;
}

export interface TextStatePanelOptions {
	dataPoint: string;
	dataSource: string;
	observationId?: number;
	template?: string;
	showDate?: boolean;
	mappers?: Mapper[];
}

function isNumeric(str: any): boolean {
	if (typeof str === "number") return true;
	if (typeof str != "string") return false; // we only process strings!
	return (
		!isNaN(str as unknown as number) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
		!isNaN(parseFloat(str))
	); // ...and ensure strings of whitespace fail
}

export default class TextStatePanel<O extends TextStatePanelOptions> {
	protected readonly options: O;

	protected readonly el: HTMLElement;

	constructor(el: HTMLElement, options: O) {
		this.el = el;
		this.options = options;
		this.init();
	}

	private isArithmeticOperation(operation: Operator): operation is ArithmeticOperator {
		return [">", ">=", "<", "<="].includes(operation);
	}

	private doesMapperApply(value: string | number, mapper: Mapper): boolean {
		if (isNumeric(value) && this.isArithmeticOperation(mapper.operator)) {
			if (mapper.operator === ">") {
				return +value > mapper.comparison;
			}
			if (mapper.operator === ">=") {
				return +value >= mapper.comparison;
			}
			if (mapper.operator === "<") {
				return +value < mapper.comparison;
			}
			if (mapper.operator === "<=") {
				return +value <= mapper.comparison;
			}
		}
		// Wichtig! Muss hier == sein
		return value == mapper.comparison;
	}

	private applyMappers(value: string | number): string {
		const mappers = this.options.mappers || [];
		const matchingMapper = mappers.find((mapper) => this.doesMapperApply(value, mapper));
		if (matchingMapper) {
			return matchingMapper.text;
		}
		return `${value}`;
	}

	protected async provideValue(): Promise<[number | number, Date]> {
		const state = await getState({
			usecase: this.options.dataSource,
			observationId: this.options.observationId ?? 0
		});
		const value = state[this.options.dataPoint];
		const date = new Date(state.time);
		return [value, date];
	}

	private getValueEl(): HTMLElement {
		const el = this.el.querySelector<HTMLElement>("[data-sp-state-value]");
		if (!el) {
			throw new Error("unable to locate value el");
		}
		return el;
	}

	private getDateEl(): HTMLElement {
		const el = this.el.querySelector<HTMLElement>("[data-sp-state-date]");
		if (!el) {
			throw new Error("unable to locate date el");
		}
		return el;
	}

	private async getValue(): Promise<[string, Date]> {
		const [value, date] = await this.provideValue();
		return [this.applyMappers(value), date];
	}

	private updateDate(date: Date): void {
		if (this.options.showDate === true) {
			this.getDateEl().innerText = date.toLocaleString();
		}
	}

	private async init(): Promise<void> {
		const [value, date] = await this.getValue();

		this.updateDisplayedValue(value);
		this.updateDate(date);
		setInterval(
			async () => {
				const [value, date] = await this.getValue();
				this.updateDisplayedValue(value);
				this.updateDate(date);
			},
			5 * 60 * 1000
		);
	}

	private updateDisplayedValue(value: string): void {
		this.getValueEl().innerHTML = this.applyTemplate(value);
	}

	private applyTemplate(value: string): string {
		const rawTemplate = this.options.template || "{value}";
		const template = rawTemplate
			.split("{value}")
			.map((part) => {
				return `<span class="SP-NumberPanel__text">${part}</span>`;
			})
			.join("{value}");

		return template.replace("{value}", `<span class="SP-NumberPanel__value">${value}</span>`);
	}
}
