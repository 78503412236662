export type DynamicRange = "today" | "week" | "month" | "year";

export function dynamicRangeToDate(range: DynamicRange): [Date, Date] {
	const now = new Date();
	if (range === "year") {
		const start = new Date(now.getFullYear(), 0, 1, 0, 0, 0, 0);
		const end = new Date(now.getFullYear(), 11, 31, 23, 59, 59, 999);
		return [start, end];
	}

	if (range === "month") {
		const start = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0, 0);
		const end = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59, 999);
		return [start, end];
	}

	if (range === "week") {
		const start = new Date();
		const day = start.getDay() || 7;
		if (day !== 1) {
			start.setHours(-24 * (day - 1));
		}
		start.setHours(0);
		start.setMinutes(0);
		start.setSeconds(0);
		start.setMilliseconds(0);

		const end = new Date(now.getFullYear(), now.getMonth(), start.getDate() + 6, 23, 59, 59, 999);
		return [start, end];
	}

	if (range === "today") {
		const start = new Date();
		start.setHours(0);
		start.setMinutes(0);
		start.setSeconds(0);
		start.setMilliseconds(0);

		const end = new Date();
		end.setHours(23);
		end.setMinutes(59);
		end.setSeconds(59);
		end.setMilliseconds(999);
		return [start, end];
	}
	throw new Error("Invalid dynamic range " + range);
}
