import getTimeRange from "../api/timeRange";
import { AbstractLineChart } from "./AbstractLineChart";

export class LineChart extends AbstractLineChart<any> {
	protected loadData(): Promise<any> {
		const [start, end] = this.getTime();
		return getTimeRange({
			usecase: this.options.data.dataSource,
			observationId: this.options.data.observationId ?? 0,
			start,
			end,
			aggregateUnit: this.options.data.aggregateUnit,
			aggregateValue: this.options.data.aggregateValue
		}).then((data) => data.rows);
	}
}
