/// <reference types="@sitepark/sitekit-js" />

import { LineChart } from "./customer/charts/line-chart/LineChart";
import NumberPanel from "./customer/charts/number-panel/NumberPanel";
import TextStatePanel from "./customer/charts/number-panel/TextStatePanel";

SP.ns("SP.kassel.chart.NumberPanel", NumberPanel);
SP.ns("SP.kassel.chart.TextStatePanel", TextStatePanel);
SP.ns("SP.kassel.chart.LineChart", LineChart);

SP.sitekit.Bootstrap.register("data-sp-chart-number-panel", "SP.kassel.chart.NumberPanel");
SP.sitekit.Bootstrap.register("data-sp-chart-textstate-panel", "SP.kassel.chart.TextStatePanel");
SP.sitekit.Bootstrap.register("data-sp-chart-line-chart", "SP.kassel.chart.LineChart");
