import { createRpcJsonAction } from "@sitepark/ts-rpc";

interface Request {
	usecase: string;
	observationId?: number;
}

type Response = {
	[key: string]: number;
	time: number;
};

const getState = createRpcJsonAction<Request, Response>(SP.env.rpcPortUrl, "SP\\Kassel\\Rpc\\Sensor", "state");

export default getState;
